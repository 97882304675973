.profileCard {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  font-weight: normal;
  cursor: pointer;
}

.aspect {
  width: 100%;
  height: 0;
  padding-bottom: 175%;
}

.media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
}

.playerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: top center;
}

.content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.priceAndPlayWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.price,
.originalPrice {
  display: inline-block;
  height: 22px;
  padding: 0 8px;
  border-radius: 11px;
  background: rgba(57, 57, 57, 0.6);
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
}

.priceWrapper {
  margin: 0 0 6px 8px;
}

.discountedPrice {
  display: inline-block;
  height: 22px;
  padding: 0 8px;
  border-radius: 11px;
  background: rgb(248, 40, 78);
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.1px;
}

.originalPrice {
  margin-bottom: 3px;
  text-decoration: line-through;
  opacity: 0.7;
}

.longPrice {
  font-size: 10px;
}

.playButton {
  box-sizing: content-box;
  width: 32px;
  height: 32px;
  padding: 10px 8px 8px 10px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

.playButton > svg {
  display: block;
}

.details {
  min-height: 85px;
  padding: 10px 10px 16px;
  background: white;
}

.detailsStripped {
  min-height: 100px;
}

.name {
  margin: 0;
}

/* Limit text to 2 lines with ellipsis */
.lineClamp {
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  min-height: 18px;
  margin: 0;
  color: #888;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
}

@media only screen and (min-width: 768px) {
  .profileCard .media img {
    transition: all 250ms ease;
  }

  .profileCard:hover .media img {
    opacity: 0.8;
    transform: scale(1.1);
  }
}
