.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.roundedTop {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: top center;
}

.wrapper :global .lazy-load-image-background {
  display: block;
  width: 100%;
  height: 100%;
}
