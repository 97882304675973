.wrapper {
  margin-bottom: 40px;
}

.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  min-height: 35px;
  padding: 0 20px;
}

.headerActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scrollButtonsWrapper {
  display: none;
}

.scrollWrapper {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollWrapper::after {
  content: '';
  display: block;
  flex-shrink: 0;
  width: 10px;
  height: 1px;
}

.loadMorePlaceholder {
  padding: 100px 100px;
  font-size: 24px;
}

.scrollWrapper::-webkit-scrollbar {
  display: none;
}

.cardWrapper {
  flex-shrink: 0;
  width: 160px;
  margin-right: 10px;
}

.cardWrapper:first-child {
  margin-left: 20px;
}

.cardWrapperLast {
  flex-shrink: 0;
  width: 160px;
  margin-right: 10px;
  padding-right: 0;
}

.loadingCard {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  height: 280px; /* 280px = same as profile card height */
  padding-bottom: 175%; /* to get dynamic height, 175% * 160px(width of parent) = 280px */
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
}

.loadingCard::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;

  /* prettier-ignore */
  background:
    linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent
    );
  transform: translateX(-100%);
  animation: loading 1.5s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

@media screen and (min-width: 769px) {
  .header {
    padding-right: 0;
    padding-left: 0;
  }

  .cardWrapper:first-child {
    margin-left: 0;
  }

  .scrollWrapper::after {
    display: none;
  }

  .cardWrapperLast {
    margin-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  .headerActions {
    height: 46px;
  }

  .scrollWrapper {
    overflow: hidden;
  }

  .scrollButtonsWrapper {
    display: flex;
  }
}

@media screen and (max-width: 1200px) {
  .loadMorePlaceholder {
    display: none;
  }
}
