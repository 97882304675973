.wrapper {
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.85);
  text-align: center;
}

.wrappertransparent {
  background-color: transparent;
}

.heading {
  padding: 15px;
}
