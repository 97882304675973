.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  line-height: 0;
}

.player {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}

@media only screen and (min-width: 768px) {
  .player:fullscreen {
    object-fit: contain;
  }
}
