.wrapper {
  display: flex;
  align-items: flex-start;
  margin: 2px 0 0 4px;
}

.star {
  margin-right: 2px;
  color: var(--colors-primary-400);
}

.number {
  color: var(--colors-surface-500);
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.02em;
}
