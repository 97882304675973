.arrowsWrapper {
  display: none;
}

@media screen and (min-width: 769px) {
  .arrowsWrapper {
    display: block;
  }
}

.overFlowX {
  overflow: scroll !important;
}
