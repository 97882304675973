.ctaLink {
  position: relative;
  display: inline-block;
  font-weight: bold;
}

.ctaLink::before {
  content: ' ';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid;
}

.lightColor {
  color: white;
}
