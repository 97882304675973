.badgeImage {
  display: block;
  width: 80px;
  height: 80px;
}

.badgeImage img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;

  /* prevent hover effect from ProfileCard */
  opacity: 1 !important;
  transform: scale(1) !important;
}
