.discountPercentageBadge {
  position: absolute;
  top: 32px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 29px;
  height: 18px;
  padding: 0;
  color: white;
  font-size: 9px;
}

.text {
  color: inherit;
  text-align: center;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.positionedTop {
  border-radius: 2px 2px 0 0;
}

.positionedRight {
  border-radius: 2px 0 0 2px;
}
