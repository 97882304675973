.tag {
  display: inline-block;
  margin: 10px 8px;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: rgb(228, 62, 102);
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
}
