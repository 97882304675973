.pause {
  vertical-align: top;
}

.pause svg {
  width: 100%;
  height: 100%;
  transform: translate(0, -8px);
}

.pauseOnBottomRight {
  transform: translate(75px, 150px);
}

.play,
.replay {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}

@media screen and (min-width: 768px) {
  .play {
    font-size: 16px;
  }

  .icon {
    width: 31px;
    height: 31px;
  }
}
