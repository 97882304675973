/* stylelint-disable font-family-no-missing-generic-family-keyword */
/* stylelint-disable value-list-comma-newline-after */
/* stylelint-disable declaration-colon-newline-after */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 33px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  padding: 26px;
}

.container.dark-red {
  background: var(--colors-secondary-900);
}

.container.red {
  background: #f8284e;
}

.container.green {
  background: #1bb55c;
}

.container.dark-gold {
  background: #303136;
}

.container.dark-rainbow {
  background: var(--colors-secondary-900);
}

.container.rainbow {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #21cd61 0deg,
    #3b6fd6 92.27deg,
    #e43ebf 191.65deg,
    #c4a331 283.52deg,
    #1fd15c 360deg
  );
}

.container.teal {
  background: #0e8eaa;
}

.title {
  margin-bottom: 9px;
  color: #fff;
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  font-family: Rubik;
  line-height: 105%;
  letter-spacing: -0.02em;
  text-align: center;
  text-transform: uppercase;
}

.title.dark-red {
  color: #f8284e;
}

.title.red {
  color: #fff;
}

.title.green {
  color: #fff;
}

.title.dark-gold {
  color: #ffbb12;
}

.title.dark-rainbow {
  background-image: linear-gradient(
    #21cd61,
    #3b6fd6,
    #e43ebf,
    #c4a331,
    #1fd15c
  );
  background-clip: text;
  color: #e43ebf;
  -webkit-text-fill-color: transparent;
}

.content {
  max-width: 250px;
  margin-right: 45px;
  margin-bottom: 9px;
  margin-left: 45px;
  color: #fff;
  font-size: 16px;
  font-family: DM Sans;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-align: center;
}

.content.dark-red {
  color: #f8284e;
}

.content.red {
  color: #fff;
}

.content.green {
  color: #fff;
}

.content.dark-gold {
  color: #eff0f4;
}

.content.dark-rainbow {
  color: #fff;
}

.remainingTimeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remainingTimeIcon {
  margin-right: 8px;
}

.remainingTimeText {
  color: #fff;
  font-size: 16px;
  font-family: DM Sans;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.remainingTimeText.dark-red {
  color: #e1e2e9;
}

.remainingTimeText.red {
  color: #fff;
}

.remainingTimeText.green {
  color: #fff;
}

.remainingTimeText.dark-gold {
  color: #ffbb12;
}

.remainingTimeText.dark-rainbow {
  color: #fff;
}

.couponCode {
  margin-top: 16px;
  padding: 6px;
  border: 1px dashed #fff;
  color: #262626;
  font-weight: bold;
  font-size: 14px;
  font-family: DM Sans;
  line-height: 100%;
  text-align: center;
}

.couponCode.rainbow > span {
  color: #fff;
}

.couponCode.rainbow > span > div {
  color: #fff;
}

.couponCode.dark-red {
  border: 1px dashed #f8284e;
}

.couponCode.dark-red > span {
  color: #f8284e;
}

.couponCode.dark-red > span > div {
  color: #f8284e;
}

.couponCode.red {
  border: 1px dashed #fff;
  color: #fff;
}

.couponCode.red > span {
  background: #f8284e;
  color: #fff;
}

.couponCode.red > span > div {
  color: #fff;
}

.couponCode.green {
  border: 1px dashed #fff;
  color: #fff;
}

.couponCode.green > span {
  color: #fff;
}

.couponCode.green > span > div {
  color: #fff;
}

.couponCode.dark-gold {
  border: 1px dashed #ffbb12;
  color: #ffbb12;
}

.couponCode.dark-gold > span {
  color: #ffbb12;
}

.couponCode.dark-gold > span > div {
  color: #ffbb12;
}

.couponCode.dark-rainbow {
  border: 1px dashed #fff;
  color: #fff;
}

.couponCode.dark-rainbow > span {
  color: #fff;
}

.couponCode.dark-rainbow > span > div {
  color: #fff;
}

.couponCode.teal > span {
  color: #fff;
}

.couponCode.teal > span > div {
  color: #fff;
}

.bookNowButton {
  width: 143px;
  height: 50px;
  margin-top: 14px;
  border-radius: 8px;
  background: rgba(39, 41, 45, 0.5);
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  font-family: DM Sans;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

.bookNowButton.dark-red {
  background: #f8284e;
  color: #fff;
}

.bookNowButton.red {
  background: #fff;
  color: #f8284e;
}

.bookNowButton.green {
  color: #fff;
}

.bookNowButton.dark-gold {
  background: #ffbb12;
  color: var(--colors-secondary-900);
}

.bookNowButton.dark-rainbow {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #21cd61 0deg,
    #3b6fd6 92.27deg,
    #e43ebf 191.65deg,
    #c4a331 283.52deg,
    #1fd15c 360deg
  );
  color: #fff;
}

.bookNowButton.teal {
  background: #fff;
  color: #f8284e;
}

@media (min-width: 768px) {
  .container {
    border-radius: 8px;
  }

  .content {
    max-width: none;
  }
}
