.wrapper {
  display: flex;
  margin: 8px 8px 8px 0;
}

.arrowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--tertiary-gray-light);
  border-radius: 50%;
  cursor: pointer;
}

.arrowButton:hover {
  background-color: var(--tertiary-gray-light);
  transform: scale(1.05);
}

.medium {
  width: 36px;
  height: 36px;
  font-size: 18.6px;
}

.small {
  width: 30px;
  height: 30px;
  font-size: 14.4px;
}

.disabled,
.disabled:hover {
  border-color: var(--tertiary-gray-light);
  background-color: transparent;
  cursor: not-allowed;
  transform: none;
}

.arrowText {
  color: var(--tertiary-gray-default);
  line-height: 10px;
  user-select: none;
}

.dark {
  border-color: transparent;
  background-color: var(--colors-surface-500);
}

.dark .arrowText {
  color: #fff;
  font-size: 22px;
}

.disabled .arrowText,
.disabled:hover .arrowText {
  color: var(--tertiary-gray-light);
}
