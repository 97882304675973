.wrapper {
  position: absolute;
}

.positionTopLeft {
  top: 8px;
  left: 8px;
}

.positionTopRight {
  top: 8px;
  right: 8px;
}
