.badgeText {
  display: inline-block;
  margin-right: 6px;
  padding: 3px 5px;
  border-radius: 4px;
  background: #ccc;
  color: white;
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.badgeText:last-child {
  margin-right: 0;
}

.small {
  padding: 1px 3px;
  font-size: 9px;
}
